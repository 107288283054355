export const classList = (...classes) => {
  return classes.filter((item) => !!item).join(' ');
};

export const selectImages = (path) => {
  let pictures = [];
  if (path.annonces.length === 0) {
    return `${process.env.REACT_APP_IMAGE_PATH}/350x240/default.jpg`;
  } else {
    path.annonces
      .filter((annonce) => !!annonce.photo)
      .map((annonce) => pictures.push(annonce.photo));
  }
  return pictures;
};

export const arrayPictures = (pics, size, default_path) => {
  let pictures = pics?.slice(0, size) ?? [];
  if (pictures.length < size && default_path)
    pictures = pictures.concat(Array(size).fill(default_path)).slice(0, size);
  return pictures;
};

export const defaultPictureName = (type, nature) => {
  let ret_val = '';
  switch ((nature || '').toLowerCase()) {
    case 'bur':
      if ((type || '').toLowerCase() == 'cow') ret_val = 'coworking';
      else ret_val = 'bureau';
      break;
    case 'act':
    case 'com':
      ret_val = 'localcommercial';
      break;
    case 'ter':
      ret_val = 'terrain';
      break;
    case 'loc':
      ret_val = 'entrepot';
      break;
    case 'fon':
      ret_val = 'fondscommerce';
      break;
    default:
      ret_val = 'default';
      break;
  }
  return ret_val + '.jpg';
};

export const shuffle = (a) => {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

export const surfaceFormat = (surfMin, surfMax, divisible, type) => {
  if (!surfMin && !surfMax) return null;
  if (surfMin && divisible)
    return `À partir de ${formatNumber(surfMin)}\u00A0${
      type == 'COW' ? `poste${surfMin > 1 ? 's' : ''}` : 'm²'
    }`;
  if (!surfMin && divisible)
    return `Jusqu'à ${formatNumber(surfMax)}\u00A0${
      type == 'COW' ? `poste${surfMax > 1 ? 's' : ''}` : 'm²'
    }`;
  if (!surfMin || !surfMax)
    return `${!surfMin ? formatNumber(surfMax) : formatNumber(surfMin)}\u00A0${
      type == 'COW'
        ? `poste${(!surfMin ? surfMax : surfMin) > 1 ? 's' : ''}`
        : 'm²'
    }`;
  else
    return `${formatNumber(surfMin)} - ${formatNumber(surfMax)}\u00A0${
      type == 'COW' ? `postes` : 'm²'
    }`;
};

export const fullSurfaceFormat = (surfMin, surfMax, divisible, shorter) => {
  if (!surfMin && !surfMax) return null;
  if (surfMin && divisible) {
    if (shorter) {
      return surfMax && surfMax != surfMin
        ? `${formatNumber(surfMin)} à ${formatNumber(surfMax)}\u00A0m²`
        : `À partir de ${formatNumber(surfMin)}\u00A0m²`;
    } else {
      return (
        (surfMax && surfMax != surfMin
          ? `${formatNumber(surfMax)}\u00A0m² divisible à`
          : 'À') + ` partir de ${formatNumber(surfMin)}\u00A0m²`
      );
    }
  }
  if (!surfMin && divisible) return `Jusqu'à ${formatNumber(surfMax)}\u00A0m²`;
  if (!surfMin || !surfMax)
    return `${formatNumber(surfMin ?? surfMax)}\u00A0m²`;
  else return `${formatNumber(surfMin)} - ${formatNumber(surfMax)}\u00A0m²`;
};

export const priceFormat = (
  priceMin,
  priceMax,
  divisible,
  tarif_mode,
  type
) => {
  if ((!priceMin && !priceMax) || type == 'COW') return null;
  let unit = '€';
  if (!!tarif_mode && !!type) {
    unit =
      tarif_mode == 'SQUARE'
        ? type == 'VEN'
          ? '€/m²'
          : '€/m²/an'
        : type == 'VEN'
        ? '€'
        : '€/mois';
  }

  if (priceMin && divisible)
    return `À partir de ${formatNumber(priceMin)}\u00A0${unit}`;
  if (!priceMin && divisible)
    return `Jusqu'à ${formatNumber(priceMax)}\u00A0${unit}`;
  if (!priceMin || !priceMax)
    return `${formatNumber(priceMin ?? priceMax)}\u00A0${unit}`;
  else
    return `${formatNumber(priceMin)} - ${formatNumber(priceMax)}\u00A0${unit}`;
};

export const priceWithUnitFormat = (type, price_m2, price) => {
  if (!type) return null;
  if (type == 'COW') return `Prix\u00A0sur\u00A0demande`;
  if ((price == null || price <= 0) && (price_m2 == null || price_m2 <= 0))
    return (type == 'LOC' ? 'Loyer' : 'Prix') + '\u00A0sur\u00A0demande';
  //if (type == 'COW') return `${formatNumber(price)}\u00A0<i>€/mois/poste</i>`;
  if (price_m2 != null && price_m2 > 0)
    return `${formatNumber(price_m2)}\u00A0<i>€/m²${
      type == 'LOC' ? '/an' : ''
    }</i>`;
  if (price != null && price > 0)
    return `${formatNumber(price)}\u00A0<i>€${
      type == 'LOC' ? '/mois' : ''
    }</i>`;
  return (type == 'LOC' ? 'Loyer' : 'Prix') + '\u00A0sur\u00A0demande';
};

export const priceWithUnitFormatTransaction = (type, price_m2, price) => {
  if (!type) return null;
  if (type == 'COW') return `Prix\u00A0non\u00A0communiqué`;
  if ((price == null || price <= 0) && (price_m2 == null || price_m2 <= 0))
    return (type == 'LOC' ? 'Loyer' : 'Prix') + '\u00A0non\u00A0communiqué';
  //if (type == 'COW') return `${formatNumber(price)}\u00A0<i>€/mois/poste</i>`;
  if (price_m2 != null && price_m2 > 0)
    return `${formatNumber(price_m2)}\u00A0<i>€/m²${
      type == 'LOC' ? '/an' : ''
    }</i>`;
  if (price != null && price > 0)
    return `${formatNumber(price)}\u00A0<i>€${
      type == 'LOC' ? '/mois' : ''
    }</i>`;
  return (type == 'LOC' ? 'Loyer' : 'Prix') + '\u00A0non\u00A0communiqué';
};

export const frequenceAlerte = (fr) => {
  switch (fr) {
    case 'QU':
      return 'Quotidienne';
    case 'HE':
      return 'Hebdomadaire';
  }
};

export const typeNatFormat = (type, nature) => {
  if (!type && !nature) return;
  if (type == 'COW' || (type && !nature)) return typeFormat(type);
  else if (!type && nature) {
    return natureFormat(nature);
  } else {
    return (
      natureFormat(nature) + ' ' + (type == 'LOC' ? 'à louer' : 'à vendre')
    );
  }
};

export const typeFormat = (type) => {
  switch (type) {
    case 'VEN':
      return 'Vente';
    case 'LOC':
      return 'Location';
    case 'COW':
      return 'Coworking';
    case 'NC':
      return 'Indéterminé';
    default:
      return;
  }
};

export const natureFormat = (nature, p = true) => {
  switch (nature) {
    case 'BUR':
      return p ? 'Bureaux' : 'Bureau';
    case 'COM':
      return p ? 'Locaux Commerciaux' : 'Local commercial';
    case 'TER':
      return p ? 'Terrains' : 'Terrain';
    case 'LOC':
      return p ? 'Entrepôts' : 'Entrepôt';
    case 'FON':
      return 'Fonds de commerce';
    case 'ACT':
      return "Local d'activité";
    case 'NC':
      return 'Indéterminé';
    default:
      return;
  }
};

export const typeOptions = [
  { value: 'VEN', label: 'Achat / Vente' },
  { value: 'LOC', label: 'Location' },
  { value: 'COW', label: 'Coworking / Bureaux flexibles' }
  //{ value: 'NC', label: 'Source inconnue' }
];

export const natureOptions = [
  { value: 'BUR', label: 'Bureaux' },
  { value: 'COM', label: 'Locaux Commerciaux' },
  { value: 'TER', label: 'Terrains' },
  { value: 'LOC', label: 'Entrepôts' },
  { value: 'FON', label: 'Fonds de commerce' },
  { value: 'ACT', label: "Local d'activité" }
  //{ value: 'NC', label: 'Source inconnue' }
];
export const annoncesNatureOptions = [
  { value: 'BUR', label: 'Bureaux' },
  { value: 'COM', label: 'Locaux Commerciaux' },
  { value: 'TER', label: 'Terrains' },
  { value: 'LOC', label: "Entrepôts / Local d'activité" },
  { value: 'FON', label: 'Fonds de commerce' }
  // { value: 'NC', label: 'Source inconnue' }
];
export const monthsOptions = [
  { value: '0', label: 'Janvier' },
  { value: '1', label: 'Février' },
  { value: '2', label: 'Mars' },
  { value: '3', label: 'Avril' },
  { value: '4', label: 'Mai' },
  { value: '5', label: 'Juin' },
  { value: '6', label: 'Juillet' },
  { value: '7', label: 'Août' },
  { value: '8', label: 'Septembre' },
  { value: '9', label: 'Octobre' },
  { value: '10', label: 'Novembre' },
  { value: '11', label: 'Décembre' }
];

export const objetOptions = [
  { label: 'N.C.', value: 'N.C' },
  { label: 'Extension', value: 'Extension' },
  { label: 'Déménagement', value: 'Déménagement' },
  { label: 'Domiciliation', value: 'Domiciliation' },
  { label: 'Investissement', value: 'Investissement' }
];

export const subjectOptions = [
  {
    value: 'CONTACT',
    label: "J'ai une question, j'aimerais être recontacté par votre équipe"
  },
  {
    value: 'ACCOMPAGNEMENT',
    label: "J'aimerai être accompagné pour définir mes besoins"
  },
  {
    value: 'RECHERCHE',
    label: "J'aimerai en savoir plus sur le déroulé des recherches."
  },
  {
    value: 'PROJETS',
    label: 'Pouvons-nous faire un point sur mes projets ?'
  },
  { value: 'AUTRE', label: 'Autre' }
];

export const selectDefaultValue = (arr, input) => {
  return arr.filter((e) => e.value === input)[0];
};

export const selectDefaultValues = (arr, values) => {
  let ids = values.map((e) => e.value);
  return arr.filter((e) => ids.indexOf(e.value) !== -1);
};

export const clearCache = () => {
  if (typeof caches != 'undefined')
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
};

export const clearLocalStorage = () => {
  window.localStorage.setItem('alertsPage', null);
};

//blue
export const colors = [
  '#124c6d',
  '#18243e',
  '#0c789f',
  '#06a4d1',
  '#00ccff',
  '#28cccc',
  '#54cd94',
  '#80ce5c',
  '#a8ce29'
];

export const colorsGreen = [
  '#a8ce29',
  '#98ba24',
  '#87a61f',
  '#779319',
  '#667f14',
  '#566b0f'
];

export const formatData = (input, formatFunc, clrs) => {
  const keys = Object.keys(input);
  let output = [];
  clrs = clrs || colors;
  keys.map((key, i) => {
    output = [
      ...output,
      {
        id: key,
        value: input[key],
        label: formatFunc(key),
        color: clrs[i % colors.length]
      }
    ];
  });
  return output;
};

export const formatNumber = (number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00A0');
};

export const generateSkeletons = (skeleton, numOfSkeletons, direction, gap) => {
  const output = [];
  for (let i = 0; i < numOfSkeletons; i++) {
    output.push(skeleton);
  }
  return (
    <div style={{ display: 'flex', flexDirection: direction, gap }}>
      {output.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

export const isNullInteger = (entry) => {
  return !!!entry ? null : +entry;
};

export const returnMessages = (obj) => {
  const keys = Object.values(obj);
  return keys.map((item) => {
    return item.message ? item.message : returnMessages(item);
  });
};

export const dateWithoutTimezone = (date) => {
  let userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
};

function formatToDatePicker(date) {
  return `${date.toLocaleString('fr-FR', {
    year: 'numeric'
  })}-${date.toLocaleString('fr-FR', {
    month: '2-digit'
  })}-${date.toLocaleString('fr-FR', { day: '2-digit' })}`;
}

export const addDaysToDate = (date, days) => {
  let res = new Date(date);
  res.setDate(res.getDate() + days);
  return res;
};

export const weekOfYear = (currentdate) => {
  let oneJan = new Date(currentdate.getFullYear(), 0, 1);
  let numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  let result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  return result;
};

export const secondsToTime = (seconds) => {
  let hhmmss = new Date(1000 * seconds).toISOString().substr(11, 8);
  let t = hhmmss.split(':');
  let h = t[0] == '00' ? '' : t[0] + 'h';
  let m = t[1] == '00' ? '' : t[1] + (t[0] == '00' && t[2] == '00' ? '' : 'm');
  let s = t[2] == '00' ? '0s' : t[2] + 's';
  return h + m + s;
};

export const onEnter = (event, callback) => {
  const key = event.charCode || event.keyCode || event.which;
  if (key === 13 && callback) {
    callback();
  }
};

export const removeHTML = (str) => {
  var tmp = document.createElement('div');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
};
