import { useEffect, useState } from 'react';

const useScroll = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(null);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    let direction = currentScrollPos <= 52 || prevScrollPos >= currentScrollPos;
    if (direction != visible) setVisible(direction);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return visible;
};

export default useScroll;
