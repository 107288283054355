import {
  useContext,
  useState,
  useEffect,
  useCallback,
  FC,
  useRef
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { emptyNumber } from 'global/yupHelper';
import axios from 'axios';

import { UserContext } from 'contexts/UserContext';
import { ProgressContext } from 'contexts/ProgressContext';
import { ContactContext } from 'contexts/ContactContext';

import useWidth from 'hooks/useWidth';
import { fetchUserData } from 'global/queries';
import { clearCache } from 'global/util';

import Annonceur, { AnnonceurSideActions } from './Annonceur';
import Utilisateur from './Utilisateur';

import UpperSection from 'components/Layout/UpperSection';
import Main from 'components/Layout/Main';
import PageWrapper from 'components/PageWrapper/PageWrapper';
import PwdStrength from 'components/PwdStrength/PwdStrength';
import Button from 'components/Button/Button';
import CardTitle from 'components/Layout/CardTitle';
import {
  FormInput,
  InputsLine,
  FormRadio,
  InputsLike
} from 'components/Form/Form';
import Loader from 'components/Loader/Loader';

import styles from './profile.module.scss';
import styles_button from 'components/Button/button.module.scss';
import formStyles from 'components/Form/form.module.scss';

import { BiLogOutCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const Home = () => {
  const { isPro, isBroker, user, setIsLogged } = useContext(UserContext);
  const { setShowFormContact } = useContext(ContactContext);

  const queryClient = useQueryClient();

  const handleLogout = async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/logout`,
      {},
      { withCredentials: true }
    );
    setIsLogged(false);
    queryClient.removeQueries();
    clearCache();
  };

  return (
    <PageWrapper>
      <Main>
        <UpperSection className={styles.upperSection}>
          <div className={styles.wrapped}>
            <h1>
              {isPro || isBroker
                ? 'Informations, abonnement et factures'
                : 'Apprenons à nous connaître ' + user.prenom}
            </h1>
            <p>
              {isPro || isBroker ? (
                <>
                  Vous avez une question ? Vous souhaitez mettre à jour
                  certaines informations affichées sur cet onglet ?{' '}
                  <span
                    className={styles.lnkLike}
                    onClick={() => setShowFormContact((prev) => !prev)}
                  >
                    Contactez{' '}
                    {user.commercial ? user.commercial.prenom : 'Quentin'}
                  </span>
                  .
                </>
              ) : (
                'Complétez ou modifiez vos informations de contact ou votre mot de passe dans cet espace.'
              )}
            </p>
          </div>
          {isPro && (
            <div className={styles.side}>
              <AnnonceurSideActions />
            </div>
          )}
        </UpperSection>
        <div className={styles.container}>
          {isPro || isBroker ? <Annonceur /> : <Utilisateur />}
          <div className={styles.logoutWrapper}>
            <Button
              className={[styles_button.button, styles.logout]}
              onClick={() => handleLogout()}
            >
              <BiLogOutCircle />
              <span>Me déconnecter</span>
            </Button>
          </div>
        </div>
      </Main>
    </PageWrapper>
  );
};

export default Home;

const validationSchemaCoords = yup.object().shape({
  civilite: yup
    .boolean()
    .required()
    .typeError('Veuillez renseigner une civilité'),
  nom: yup.string().required('Veuillez renseigner un nom'),
  prenom: yup.string().required('Veuillez renseigner un prénom'),
  cp: yup.string(),
  adresse: yup.string(),
  ville: yup.string(),
  tel: yup.string().required('Veuillez renseigner un téléphone'),
  email: yup
    .string()
    .email('Veuillez renseigner un email valide')
    .required('Veuillez renseigner un email valide'),
  can_contact: yup
    .boolean()
    .nullable()
    .notRequired()
    .transform((_, val) => (val ?? '' !== '' ? val : false)),
  societe_in_creation: yup
    .boolean()
    .nullable()
    .notRequired()
    .transform((_, val) => (val ?? '' !== '' ? val : false)),
  societe: yup
    .string()
    .nullable()
    .notRequired()
    .when('societe_in_creation', {
      is: false,
      then: yup
        .string()
        .nullable()
        .required('Veuillez renseigner une société')
        .transform((_, val) => (val ?? '' !== '' ? val : null)),
      otherwise: yup
        .string()
        .nullable()
        .notRequired()
        .transform((_, val) => null)
    }),
  nb_collaborateur: emptyNumber(
    'Veuillez renseigner un nombre pour nombre de collaborateur'
  ).positive('Le nombre de collaborateur doit être positif')
});

export const UserForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchemaCoords)
  });
  const { isPro, isBroker, user } = useContext(UserContext);
  const { isProfileFilled, setIsProfileFilled } = useContext(ProgressContext);
  const { data: userData, status } = useQuery('fetchUserData', fetchUserData);

  const [isExpanded, setIsExpanded] = useState(true);
  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, []);

  const queryClient = useQueryClient();

  const inCrea = watch('societe_in_creation');

  const updateProfile = async (data) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/user/update`, data, {
      withCredentials: true
    });
  };

  const mutation = useMutation(updateProfile, {
    onError: () => {
      toast.error('Une erreur est survenue...');
    },
    onSuccess: () => {
      toast.success(`Votre profil a bien été mis à jour`);
      !isProfileFilled && setIsProfileFilled(true);
      queryClient.invalidateQueries('fetchUserData');
    }
  });

  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      id: user.id
    };
    mutation.mutate(updatedData);
  };

  useEffect(() => {
    if (errors) {
      const errorMessages = Object.values(errors);
      errorMessages.map((msg) => toast.error(msg.message));
    }
  }, [errors]);

  useEffect(() => {
    if (!!inCrea) {
      setValue('societe', '');
    }
  }, [inCrea]);

  const width = useWidth();

  const showLabel = (desktop, mobile) => {
    return width <= 1024 ? mobile || desktop : desktop;
  };

  return (
    <div
      className={[styles.tuile, styles.coords].join(' ')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <CardTitle title={`Coordonnées`} className={styles.cardTitle} />
      {status === 'loading' ? (
        <Loader />
      ) : (
        <form>
          {!isPro && !isBroker && (
            <>
              <InputsLine
                className={`${styles.civilite} ${
                  errors.civilite && formStyles.error
                }`}
              >
                <label>Civilité *</label>
                <InputsLike>
                  <FormRadio
                    name="civilite"
                    id="civilite_mr"
                    label="Mr"
                    value={true}
                    checked={userData?.civilite}
                    register={register}
                    disabled={isPro || isBroker}
                  />
                  <FormRadio
                    name="civilite"
                    id="civilite_mme"
                    label="Mme"
                    value={false}
                    checked={!userData?.civilite}
                    register={register}
                    disabled={isPro || isBroker}
                  />
                </InputsLike>
              </InputsLine>
              <FormInput
                name="nom"
                id="nom_profil"
                label="Nom *"
                defaultValue={userData?.nom}
                register={register}
                className={errors.nom && formStyles.error}
                disabled={isPro || isBroker}
              />
              <FormInput
                name="prenom"
                label="Prénom *"
                defaultValue={userData?.prenom}
                register={register}
                className={errors.prenom && formStyles.error}
                disabled={isPro || isBroker}
              />
            </>
          )}
          <InputsLine className={styles.inputSociete}>
            <label htmlFor="societe_in_creation">
              Société{`${isPro || isBroker ? '' : '* '}`}
            </label>
            <div>
              {!isPro && !isBroker && (
                <div className={styles.inCreation}>
                  <input
                    type="checkbox"
                    id="societe_in_creation"
                    name="societe_in_creation"
                    {...register('societe_in_creation')}
                    defaultChecked={userData?.societe_in_creation}
                    disabled={isPro || isBroker}
                  />
                  <label htmlFor="societe_in_creation">En création</label>
                </div>
              )}
              <FormInput
                name="societe"
                defaultValue={userData?.societe}
                className={errors.societe && formStyles.error}
                register={register}
                disabled={isPro || isBroker || !!inCrea}
              />
            </div>
          </InputsLine>
          <FormInput
            id="email_profil"
            name="email"
            type="email"
            label={`Email ${isPro || isBroker ? '' : '* '}`}
            defaultValue={userData?.email}
            register={register}
            className={errors.email && formStyles.error}
            disabled={isPro || isBroker}
          />
          <FormInput
            id="tel_profil"
            name="tel"
            label={`Téléphone ${isPro || isBroker ? '' : '* '}`}
            defaultValue={userData?.tel}
            className={errors.tel && formStyles.error}
            register={register}
            disabled={isPro || isBroker}
          />
          {!isBroker && !isPro && (
            <>
              <FormInput
                name="adresse"
                label="Adresse"
                defaultValue={userData?.adr}
                className={errors.adr && formStyles.error}
                register={register}
                disabled={isPro || isBroker}
              />
            </>
          )}
          {!isBroker && !isPro && (
            <>
              <FormInput
                name="cp"
                label="Code postal"
                defaultValue={userData?.cp}
                className={errors.cp && formStyles.error}
                register={register}
                disabled={isPro || isBroker}
              />
              <FormInput
                name="ville"
                label="Ville"
                defaultValue={userData?.ville}
                className={errors.ville && formStyles.error}
                register={register}
                disabled={isPro || isBroker}
              />
            </>
          )}

          {!isPro && (
            <FormInput
              name="nb_collaborateur"
              label={showLabel('Nombre de collaborateurs', 'Collaborateurs')}
              defaultValue={userData?.nb_collaborateur}
              className={errors.nb_collaborateur && formStyles.error}
              register={register}
              disabled={isPro || isBroker}
            />
          )}

          {!isPro && !isBroker && (
            <>
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  id="can_contact"
                  name="can_contact"
                  {...register('can_contact')}
                  defaultChecked={userData?.can_contact}
                />
                <label htmlFor="can_contact">
                  J'accepte d'être contacté(e) par Geolocaux
                </label>
              </div>
              <Button className={styles.button} type="submit">
                <p>Enregistrer mon profil</p>
              </Button>
            </>
          )}
          {/* {!isPro && !isBroker && (
            <>
              <span
                className={styles.toggleCollapse}
                onClick={() => toggleIsExpanded()}
              >
                {isExpanded ? 'En voir moins' : 'En voir plus'}
              </span>
            </>
          )} */}
        </form>
      )}
    </div>
  );
};

const validationSchemaPwd = yup.object().shape({
  txt_password: yup
    .string()
    .trim()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial(@$!%*#?&).'
    )
    .required('Mot de passe requis'),
  txt_passwordConfirm: yup
    .string()
    .required('Veuillez confirmer votre mot de passe')
    .oneOf(
      [yup.ref('txt_password'), null],
      'Les mots de passe doivent être identiques'
    )
});

export const UserPasswordForm = () => {
  const [pwdValid, setPwdValid] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchemaPwd)
  });
  const { user } = useContext(UserContext);

  const watchPwd = watch('txt_password', '');

  const onSubmit = (data) => {
    const updatePassword = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/user/update/password`,
          {
            id: user.id,
            password: data.txt_password
          },
          { withCredentials: true }
        );
        toast.success(`Mot de passe modifié`);
      } catch (err) {
        toast.error('Une erreur est survenue...');
      }
    };
    updatePassword();
  };

  useEffect(() => {
    if (errors) {
      const errorMessages = Object.values(errors);
      errorMessages.map((msg) => toast.error(msg.message));
    }
  }, [errors]);

  const width = useWidth();

  const showLabel = (desktop, mobile) => {
    return width <= 1024 ? mobile || desktop : desktop;
  };

  return (
    <div
      className={[styles.tuile, styles.passwordForm].join(' ')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <CardTitle title={`Mot de passe`} className={styles.cardTitle} />
      <form>
        <FormInput
          name="txt_password"
          label={showLabel('Mot de passe', 'Mot de passe')}
          register={register}
          type="password"
          autoComplete="new-password"
          className={errors.txt_password && formStyles.error}
        />
        <PwdStrength
          pwd={watchPwd}
          setPwdValid={setPwdValid}
          className={styles.pwdStrength}
        />
        <FormInput
          name="txt_passwordConfirm"
          label={showLabel('Confirmez le mot de passe', 'Confirmation')}
          register={register}
          type="password"
          autoComplete="new-password"
          className={errors.txt_passwordConfirm && formStyles.error}
        />
        <Button className={styles.button} type="submit" disabled={!pwdValid}>
          Mettre à jour
        </Button>
      </form>
    </div>
  );
};

const Collapse = ({ isExpanded, children }) => {
  const ref = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.clientHeight);
    }
  }, [children]);

  return (
    <div
      className={styles.collapse}
      style={{
        height: isExpanded ? contentHeight + 16 : 0
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
};
